module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"/Users/DHindman/git/cooperative-minds/code/src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"dd402a40c94c4fb070847e8331d20eee"},
    },{
      plugin: require('../node_modules/@discoveryed/de-analytics/gatsby-browser.js'),
      options: {"plugins":[],"partnershipId":"8c1bce89-d21a-49df-bb8d-8953f0edf267","extraDevHostnames":[],"extraStageHostnames":[],"extraProdHostnames":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
